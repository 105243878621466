import { gql } from "@apollo/client";

export const getAllChoreoQuery = gql`
  query AllChoreoList {
    GetLatestChoreoList {
      additional_info
      video_preview_url_cdn
      video_thumbnail_url_cdn
      choreo_id
      choreographer_data {
        choreographer_id
        choreographer_name
        profile_image_url
      }
      choreographer_id
      current_price
      description
      difficulty
      duration
      is_active
      like_count
      liked
      music_data {
        title
        artist_name
      }
      music_id
      order
      saved
      title
      unlocked
      video_preview_url
      video_thumbnail_url
    }
  }
`;

export const getChoreoDetailByChoreoIdQuery = gql`
  query ChoreoDetailByChoreoId($choreo_id: Int!) {
    GetChoreoDetailByChoreoID(choreo_id: $choreo_id) {
      additional_info
      choreo_detail_id
      choreo_id
      duration
      is_active
      order
      position
      title
      video_thumbnail_url
      video_url
      video_url_cdn
      vision_angle_threshold
      vision_body_pose
      vision_time_offset
      test_video_url
      test_video_url_cdn
    }
  }
`;

export const getAllChoreographerQuery = gql`
  query GetChoreographer {
    GetAllChoreographers {
      choreographer_id
      choreographer_name
      description
      profile_image_url
    }
  }
`;
