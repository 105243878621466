import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/login";
import Home from "../pages/home";
import CreateContent from "../pages/create-content";
import Maintenance from "../pages/maintenance";
import Choreographers from "../pages/choreographers";

/**
 * This is the root component of the react application.
 * This component handle all navigation in the web app.
 * You need to define all URL path in this component to be able to use the navigation.
 *
 * @returns Router Component
 */
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/create" element={<CreateContent />} />
        <Route exact path="/maintenance" element={<Maintenance />} />
        <Route exact path="/choreographers" element={<Choreographers />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
