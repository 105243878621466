import React from "react";
import style from "./style.module.css";
import TextField from "../textfield";
import PropTypes from "prop-types";
import { Button } from "antd";
import { deleteChoreoDetail } from "../../api/rest/choreo-api";

/**
 * VideoBreakdownItem is a component that use to define video breakdown needed for the uploaded choreo
 *
 * @param {Number} index This is an index to differentiate between VideoBreakdownItem component
 * @param {Object} choreoDetail This is an object that use to store choreo detail from the parent view that use VideoBreakdownItem component
 * @param {Function} handleTitleChange This is a callback that will be called when there is a value changing in textfieldValue
 * @param {Function} handleFileChanges This is a callback that will be called when there is a file changing inside the view component
 *
 * @returns VideoBreakdownItem View Component
 */
const VideoBreakdownItem = ({
  index,
  choreoDetail,
  handleTitleChange,
  handleFileChanges,
}) => {
  const handleDeleteChoreoDetail = () => {
    deleteChoreoDetail(parseInt(choreoDetail.choreo_detail_id)).then(
      (response) => {
        if (response.status == 200) {
          // Force reload all page. Need to find better way
          window.location.reload(false);
        }
      }
    );
  };

  return (
    <div className={style.contentItem}>
      <TextField
        title="Video Title"
        textfieldName="title"
        textfieldValue={choreoDetail.title}
        onInputValueChange={(event) => handleTitleChange(index, event, "title")}
      />
      <TextField
        title="Duration"
        textfieldName="duration"
        textfieldValue={choreoDetail.duration}
        onInputValueChange={(event) =>
          handleTitleChange(index, event, "duration")
        }
      />
      <TextField
        title="Test Me Out JSON"
        textfieldName="tmoJSON"
        textfieldValue={choreoDetail.vision_body_pose}
        onInputValueChange={(event) =>
          handleTitleChange(index, event, "vision_body_pose")
        }
      />
      <TextField
        title="Order"
        textfieldName="order"
        textfieldValue={choreoDetail.order}
        onInputValueChange={(event) => handleTitleChange(index, event, "order")}
      />
      <div className={style.videoInputContainer}>
        <h1>Lesson Video</h1>
        <input
          type="file"
          title="Lesson Video"
          className={style.fileInput}
          onChange={(event) => handleFileChanges(index, event, "video_url_cdn")}
        />
      </div>
      <div className={style.videoInputContainer}>
        <h1>Thumbnail</h1>
        <input
          type="file"
          title="Thumbnail"
          className={style.fileInput}
          onChange={(event) =>
            handleFileChanges(index, event, "video_thumbnail_url_cdn")
          }
        />
      </div>
      <div className={style.videoInputContainer}>
        <h1>Test Me Out Video</h1>
        <input
          type="file"
          title="Test Me Out Video"
          className={style.fileInput}
          onChange={(event) =>
            handleFileChanges(index, event, "test_video_url_cdn")
          }
        />
      </div>
      <Button onClick={handleDeleteChoreoDetail}>Delete</Button>
    </div>
  );
};

VideoBreakdownItem.propTypes = {
  index: PropTypes.number,
  choreoDetail: PropTypes.object,
  handleTitleChange: PropTypes.func,
  handleFileChanges: PropTypes.func,
};

export default VideoBreakdownItem;
