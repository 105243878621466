import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Layout } from "antd";
import {
  FileInput,
  NavBar,
  Picker,
  SideBar,
  TextField,
  ChoreographerModal,
} from "../../components";
import style from "./style.module.css";
import PropTypes from "prop-types";
import DanceElementSectionForm from "./components/dance-element-section-form";
import VideoBreakdownSectionForm from "./components/video-breakdown-section-form";
import {
  getAllChoreographerQuery,
  getChoreoDetailByChoreoIdQuery,
  getAllChoreoQuery,
} from "../../api/gql/query";
import { apolloClient } from "../../api/gql/client";
import { useLocation } from "react-router-dom";
import {
  updateChoreo,
  createChoreo,
  uploadFileChoreo,
  createChoreoDetail,
  updateChoreoDetail,
  uploadFileChoreoDetail,
} from "../../api/rest/choreo-api";
import { upsertMusic } from "../../api/rest/music-api";
import {
  upsertChoreographer,
  uploadFileChoreographer,
} from "../../api/rest/choreographer-api";

// Extract Design Component from AntDesign
const { Header, Content } = Layout;

const FormData = require("form-data");

const courseCategorySelection = [
  { value: "1", label: "Beginner" },
  { value: "2", label: "Intermediate" },
  { value: "3", label: "Advanced" },
  { value: "4", label: "Basic & Drilling" },
];

const activateSelection = [
  { value: "1", label: "True" },
  { value: "0", label: "False" },
];

const unlockedSelection = [
  { value: true, label: "True" },
  { value: false, label: "False" },
];

const MainSectionForm = ({
  choreographerSelection,
  selectedContent,
  handleInputFile,
  handleFileChanges,
  handleTextFieldChange,
  handlePickerChange,
  filePreviews,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (choreographerData) => {
    const tempImageData = choreographerData.profile_image_url_form;
    upsertChoreographer(choreographerData)
      .then(async (response) => {
        if (response.status == 200) {
          await apolloClient.refetchQueries({
            include: [getAllChoreographerQuery],
          });

          uploadFileChoreographer(
            response.data.data.choreographer_id,
            tempImageData,
            1
          )
            .then(async (response) => {
              console.log("Upload choreographer file success: ", response);

              await apolloClient.refetchQueries({
                include: [getAllChoreographerQuery],
              });
            })
            .catch((error) =>
              console.log("Upload choreographer Failed: ", error)
            );
        }
      })
      .catch((error) => console.log("Upsert choreographer error: ", error));
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <TextField
        title="Choreography Title"
        textfieldName="title"
        textfieldValue={selectedContent.title}
        onInputValueChange={(event) => handleTextFieldChange(event, "title")}
      />
      <TextField
        title="Choreography Description"
        textfieldName="description"
        textfieldValue={selectedContent.description}
        onInputValueChange={(event) =>
          handleTextFieldChange(event, "description")
        }
      />
      <Picker
        title="Choreographer"
        placeholder="Select Choreographer"
        onInputValueChange={(event) =>
          handlePickerChange(event, "choreographer_id")
        }
        pickerValue={
          choreographerSelection.filter(
            (choreographer) =>
              choreographer.value ===
              `${selectedContent.choreographer_id ?? ""}`
          )[0]?.label
        }
        pickerSelection={choreographerSelection}
      />
      <div className={style.otherButton}>
        <Button onClick={showModal}>Did not find choreographer?</Button>
      </div>
      <div className={style.priceRowContainer}>
        <TextField
          title="Original Price"
          textfieldName="original-price"
          textfieldValue={selectedContent.current_price}
          onInputValueChange={(event) =>
            handleTextFieldChange(event, "current_price")
          }
        />
        <TextField
          title="Sales Price"
          textfieldName="sales-price"
          textfieldValue={selectedContent.current_price}
        />
      </div>
      <h1 className={style.sectionTitle}>Lesson Preview</h1>
      <div className={style.contentInputRowContainer}>
        <div>
          <FileInput
            inputIdentifier="previewVideo"
            filePreviews={filePreviews ? filePreviews.previewVideo : null}
            handleInputFile={handleInputFile}
            handleFileChanges={(event) =>
              handleFileChanges(event, "previewVideo")
            }
          />
          <h1>Preview Video</h1>
        </div>
        <div>
          <FileInput
            inputIdentifier="videoThumbnail"
            filePreviews={filePreviews ? filePreviews.videoThumbnail : null}
            handleInputFile={handleInputFile}
            handleFileChanges={(event) =>
              handleFileChanges(event, "videoThumbnail")
            }
          />
          <h1>Thumbnail</h1>
        </div>
      </div>
      <ChoreographerModal
        isModalOpen={isModalOpen}
        handleSubmit={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  );
};

const MusicSectionForm = ({ selectedContent, handleTextFieldChange }) => {
  return (
    <div>
      <h1 className={style.sectionTitle}>Music Section</h1>
      <TextField
        title="Song Title"
        textfieldName="song-title"
        textfieldValue={selectedContent.title}
        onInputValueChange={(event) => handleTextFieldChange(event, "title")}
      />
      <TextField
        title="Artist"
        textfieldName="song-artist"
        textfieldValue={selectedContent.artist_name}
        onInputValueChange={(event) =>
          handleTextFieldChange(event, "artist_name")
        }
      />
    </div>
  );
};

const ChoreographyCourseSectionForm = ({
  selectedContent,
  handlePickerChange,
  handleTextFieldChange,
}) => {
  const courseCategory = courseCategorySelection.find((category) => {
    return category.value === `${selectedContent.difficulty}`;
  });

  return (
    <div>
      <h1 className={style.sectionTitle}>Choreography Course Section</h1>
      <TextField
        title="Course Duration (Seconds)"
        textfieldName="duration"
        textfieldValue={selectedContent.duration}
        onInputValueChange={(event) => handleTextFieldChange(event, "duration")}
      />
      <Picker
        title="Choreography Category"
        placeholder="Select Category"
        onInputValueChange={(event) => handlePickerChange(event, "difficulty")}
        pickerValue={courseCategory ? `${courseCategory.label}` : null}
        pickerSelection={courseCategorySelection}
      />
    </div>
  );
};

const CreateContent = () => {
  /**
   * Navigation property to be able to navigate to other page programatically
   */
  const navigate = useNavigate();

  const { state } = useLocation();

  const [selectedContent, setSelectedContent] = useState({});
  const [choreographerSelection, setChoreographerSelection] = useState([]);
  const [videoBreakdown, setVideoBreakdown] = useState([
    // {
    //   title: "",
    //   duration: 0,
    //   is_active: 1,
    //   video_thumbnail_url_cdn: null,
    //   video_url_cdn: null,
    //   test_video_url_cdn: null,
    //   vision_body_pose: "",
    // },
  ]);
  const [fileInputPreview, setFileInputPreview] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState({});

  // To differentiate API call for update and insert
  const [updateContent, setUpdateContent] = useState(false);

  // To auto-increment order value so that user not insert order manually
  const [currentOrder, setCurrentOrder] = useState(0);

  // To save music form local state
  const [musicContent, setMusicContent] = useState({});

  // To save online video breakdown
  const [onlineVideoBreakdown, setOnlineVideoBreakdown] = useState([]);

  useEffect(() => {
    if (state != null) {
      const { data } = state;
      setSelectedContent(data);
      setUpdateContent(true);
      setAdditionalInfo(convertDanceElementToObject(data.additional_info));
      getChoreoDetailByChoreoIdFromServer(data.choreo_id);
      getAllChoreoFromServer();

      // Set music_data (if available) to musicContent local state
      let musicData = data.music_data;
      setMusicContent({ ...musicData });

      // Set preview for video preview and thumbnail file input
      let inputPreview = {
        previewVideo: data.video_preview_url_cdn,
        videoThumbnail: data.video_thumbnail_url_cdn,
      };
      setFileInputPreview(inputPreview);
    }
  }, []);

  useEffect(() => {
    getChoreographersFromServer();
    getAllChoreoFromServer();
  }, []);

  const addVideoBreakdown = () => {
    setVideoBreakdown([...videoBreakdown, {}]);
  };

  const handleTextFieldChange = (event, identifier) => {
    let data = { ...selectedContent };
    data[identifier] = event.target.value;
    setSelectedContent(data);
  };

  const handleMusicFormTextFieldChange = (event, identifier) => {
    let data = { ...musicContent };
    data[identifier] = event.target.value;
    setMusicContent(data);
  };

  const handleAdditionalInfoChange = (event, identifier) => {
    let data = { ...additionalInfo };
    data[identifier] = event.target.value;
    setAdditionalInfo(data);
  };

  const handlePickerChange = (value, identifier) => {
    let data = { ...selectedContent };
    data[identifier] = value;
    setSelectedContent(data);
  };

  const handleCustomFileInputFile = (event) => {
    document.querySelector(`.fileInput${event.target.dataset.id}`).click();
  };

  const handleCustomFileChanges = (event, identifier) => {
    let data = { ...fileInputPreview };
    let baseFormData = new FormData();
    baseFormData.append("file", event.target.files[0]);
    data[identifier] = baseFormData;
    setFileInputPreview(data);
  };

  const onVideoPartTextFieldChange = (index, event, identifier) => {
    let data = [...videoBreakdown];
    data[index][identifier] = event.target.value;
    setVideoBreakdown(data);
  };

  const handleFileChanges = (index, event, identifier) => {
    let data = [...videoBreakdown];
    let baseFormData = new FormData();
    baseFormData.append("file", event.target.files[0]);
    data[index][identifier] = baseFormData;
    setVideoBreakdown(data);
  };

  const handleUploadContent = () => {
    console.log("Preview File: ", fileInputPreview);
    console.log("Video Breakdown: ", videoBreakdown);

    if (updateContent) {
      let additional_info = {
        dance_style: additionalInfo.dance_style,
        dance_element: additionalInfo.dance_element,
      };

      let data = {
        choreo_id: parseInt(selectedContent.choreo_id),
        title: selectedContent.title,
        description: selectedContent.description,
        is_active: parseInt(selectedContent.is_active),
        difficulty: selectedContent.difficulty,
        duration: parseInt(selectedContent.duration),
        video_preview_url: selectedContent.video_preview_url,
        video_thumbnail_url: selectedContent.video_thumbnail_url,
        video_preview_url_cdn: selectedContent.video_preview_url_cdn,
        video_thumbnail_url_cdn: selectedContent.video_thumbnail_url_cdn,
        choreographer_id: selectedContent.choreographer_id,
        music_id: selectedContent.music_id,
        like_count: selectedContent.like_count,
        liked: selectedContent.liked,
        saved: selectedContent.saved,
        additional_info: JSON.stringify(additional_info),
        unlocked: parseInt(selectedContent.unlocked),
        current_price: selectedContent.current_price,
        order: selectedContent.order,
      };

      console.log("Update Content", data);

      updateChoreo(data)
        .then((response) => {
          if (response.status == 200) {
            let choreoId = response.data.data.choreo_id;
            uploadFileChoreo(choreoId, fileInputPreview.videoThumbnail, 1)
              .then(async (uploadFileChoreoThumbnailResponse) => {
                if (uploadFileChoreoThumbnailResponse.status == 200) {
                  console.log(
                    "Upload File Choreo: ",
                    uploadFileChoreoThumbnailResponse
                  );
                }
              })
              .catch((error) => console.log("Error Response: ", error));
            uploadFileChoreo(choreoId, fileInputPreview.previewVideo, 2)
              .then((uploadFileChoreoVideoPreviewResponse) => {
                console.log(
                  "Upload File Choreo: ",
                  uploadFileChoreoVideoPreviewResponse
                );
              })
              .catch((error) => console.log("Error Response: ", error));

            // Handle video breakdown upload
            videoBreakdown.forEach((content) => {
              handleUploadBreakdownVideoContent(choreoId, content);
            });
          }
        })
        .finally(async () => {
          await apolloClient.refetchQueries({
            include: [getAllChoreoQuery],
          });
          navigate("/home");
        });
    } else {
      if (musicContent.title != null || musicContent.artist_name != null) {
        upsertMusic(musicContent).then((response) => {
          if (response.status == 200) {
            let additional_info = {
              dance_style: additionalInfo.dance_style,
              dance_element: convertDanceElementToArray(additionalInfo),
            };

            let data = {
              title: selectedContent.title,
              description: selectedContent.description,

              // By default not activate content so that there's no accidental content delivery
              is_active: 0,
              difficulty: parseInt(selectedContent.difficulty),
              duration: parseInt(selectedContent.duration),
              video_preview_url: "",
              video_thumbnail_url: "",
              video_preview_url_cdn: "",
              video_thumbnail_url_cdn: "",
              choreographer_id: parseInt(selectedContent.choreographer_id),
              music_id: response.data.data.music_id,
              like_count: 0,
              liked: false,
              saved: false,
              additional_info: JSON.stringify(additional_info),

              // By default no activate content so that there's no accidental content delivery
              unlocked: false,
              current_price: selectedContent.current_price,
              order: currentOrder,
            };

            createChoreo(data)
              .then((createChoreoResponse) => {
                console.log("Create Choreo Response: ", createChoreoResponse);
                if (createChoreoResponse.status == 200) {
                  let choreoId = createChoreoResponse.data.data.choreo_id;
                  uploadFileChoreo(choreoId, fileInputPreview.videoThumbnail, 1)
                    .then(async (uploadFileChoreoThumbnailResponse) => {
                      if (uploadFileChoreoThumbnailResponse.status == 200) {
                        console.log(
                          "Upload File Choreo: ",
                          uploadFileChoreoThumbnailResponse
                        );
                      }
                    })
                    .catch((error) => console.log("Error Response: ", error));
                  uploadFileChoreo(choreoId, fileInputPreview.previewVideo, 2)
                    .then((uploadFileChoreoVideoPreviewResponse) => {
                      console.log(
                        "Upload File Choreo: ",
                        uploadFileChoreoVideoPreviewResponse
                      );
                    })
                    .catch((error) => console.log("Error Response: ", error));

                  // Handle video breakdown upload
                  videoBreakdown.forEach((content) => {
                    handleUploadBreakdownVideoContent(choreoId, content);
                  });
                }
              })
              .finally(async () => {
                await apolloClient.refetchQueries({
                  include: [getAllChoreoQuery],
                });
                navigate("/home");
              });
          }
        });
      }
    }
  };

  const handleUploadBreakdownVideoContent = (choreoId, videoBreakdown) => {
    let onlineBreakdown = onlineVideoBreakdown.filter(
      (video) => video.order == videoBreakdown.order
    )[0];

    let data = {
      title: videoBreakdown.title,
      duration: parseInt(videoBreakdown.duration),
      is_active: 1,
      video_url: "", // By default is empty. Since this is use to store ODR Tag
      video_url_cdn: "",
      video_thumbnail_url: "", // By default is empty. Since this is use to store ODR Tag
      video_thumbnail_url_cdn: "",
      test_video_url: "", // By default is empty. Since this is use to store ODR Tag
      test_video_url_cdn: "",
      choreo_id: parseInt(choreoId),
      vision_time_offset: videoBreakdown.vision_time_offset ?? 0,
      vision_angle_threshold: videoBreakdown.vision_angle_threshold ?? 0,
      vision_body_pose: videoBreakdown.vision_body_pose ?? "",
      order: parseInt(videoBreakdown.order),
    };

    console.log("Handle Upload Video Breakdown: ", data);
    console.log("Video Breakdown: ", videoBreakdown);

    if (updateContent) {
      console.log("Update Content Choreo Detail");
      let updateData = {
        choreo_detail_id: parseInt(onlineBreakdown.choreo_detail_id),
        ...data,
      };
      updateChoreoDetail(updateData)
        .then((response) => {
          if (response.status == 200) {
            let choreoDetailId = response.data.data.choreo_detail_id;
            uploadFileChoreoDetail(
              choreoDetailId,
              videoBreakdown.video_url_cdn,
              2
            )
              .then((response) => {
                console.log("Upload File Choreo Detail Response: ", response);
              })
              .catch((error) =>
                console.log("Upload Video URL CDN Failed: ", error)
              );
            uploadFileChoreoDetail(
              choreoDetailId,
              videoBreakdown.video_thumbnail_url_cdn,
              1
            )
              .then((response) => {
                console.log(
                  "Upload File Thumbnail Choreo Detail Response: ",
                  response
                );
              })
              .catch((error) =>
                console.log("Upload Thumbnail URL CDN Failed: ", error)
              );
          }
          console.log("Update Choreo Detail Response: ", response);
        })
        .catch((error) => console.log("Update Choreo Detail Failed: ", error));
    } else {
      createChoreoDetail(data)
        .then((response) => {
          if (response.status == 200) {
            let choreoDetailId = response.data.data.choreo_detail_id;
            uploadFileChoreoDetail(
              choreoDetailId,
              videoBreakdown.video_url_cdn,
              2
            )
              .then((response) => {
                console.log("Upload File Choreo Detail Response: ", response);
              })
              .catch((error) =>
                console.log("Upload Video URL CDN Failed: ", error)
              );
            uploadFileChoreoDetail(
              choreoDetailId,
              videoBreakdown.video_thumbnail_url_cdn,
              1
            )
              .then((response) => {
                console.log(
                  "Upload File Thumbnail Choreo Detail Response: ",
                  response
                );
              })
              .catch((error) =>
                console.log("Upload Thumbnail URL CDN Failed: ", error)
              );
          }
          console.log("Create Choreo Detail Response: ", response);
        })
        .catch((error) => console.log("Create Choreo Detail Failed: ", error));
    }
  };

  const getChoreographersFromServer = () => {
    apolloClient
      .query({ query: getAllChoreographerQuery })
      .then((response) => {
        const { data } = response;
        const choreographersRemoteData = data.GetAllChoreographers.map(
          (choreographer) => ({
            value: choreographer.choreographer_id,
            label: choreographer.choreographer_name,
          })
        );
        setChoreographerSelection(choreographersRemoteData);
      })
      .catch((error) => console.log("Error:", error));
  };

  const getChoreoDetailByChoreoIdFromServer = (id) => {
    apolloClient
      .query({
        query: getChoreoDetailByChoreoIdQuery,
        variables: { choreo_id: id },
      })
      .then((response) => {
        const { data } = response;
        const videoBreakdownRemoteData = data.GetChoreoDetailByChoreoID.map(
          (choreo) => ({
            choreo_detail_id: choreo.choreo_detail_id,
            title: choreo.title,
            duration: choreo.duration,
            is_active: choreo.is_active,
            video_url_cdn: choreo.video_url_cdn,
            video_thumbnail_url_cdn: choreo.video_thumbnail_url_cdn,
            test_video_url_cdn: choreo.test_video_url_cdn,
            vision_body_pose: choreo.vision_body_pose,
            order: choreo.order,
          })
        );
        setVideoBreakdown(videoBreakdownRemoteData);
        setOnlineVideoBreakdown(videoBreakdownRemoteData);
      })
      .catch((error) => console.log("Error:", error));
  };

  const getAllChoreoFromServer = () => {
    apolloClient
      .query({ query: getAllChoreoQuery })
      .then((response) => {
        const { data } = response;

        // Need to slice() the array to be able to sort by order
        const sortedChoreo = data.GetLatestChoreoList.slice().sort(
          (firstChoreo, secondChoreo) => firstChoreo.order - secondChoreo.order
        );
        const setNextOrderBasedOnLastOrder = sortedChoreo.length - 1;
        setCurrentOrder(setNextOrderBasedOnLastOrder);
      })
      .catch((error) => console.log("Error:", error));
  };

  const convertDanceElementToArray = (value) => {
    const { dance_element } = value;

    if (dance_element != null) {
      const removedString = dance_element.replace(/ /g, "");
      const splitedItemToArray = removedString.split(",");
      return splitedItemToArray;
    }
  };

  const convertDanceElementToObject = (value) => {
    const additionalInfo = JSON.parse(value ? value : "{}", (key, value) => {
      return value;
    });
    return additionalInfo;
  };

  return (
    <Layout>
      <Header className={style.header}>
        <NavBar currentNavigation="create" onClick={handleUploadContent} />
      </Header>
      <Layout>
        <SideBar navigationKey="create" />
        <Content className={style.mainContent}>
          <h1 className={style.mainTitle}>Create Content</h1>
          <Picker
            title="Activate Content"
            placeholder="Activate Content"
            onInputValueChange={(event) =>
              handlePickerChange(event, "is_active")
            }
            pickerValue={
              activateSelection.filter(
                (selection) =>
                  selection.value === `${selectedContent.is_active ?? 0}`
              )[0]?.label
            }
            pickerSelection={activateSelection}
          />
          <Picker
            title="Unlock Content"
            placeholder="Unlock Content"
            onInputValueChange={(event) =>
              handlePickerChange(event, "unlocked")
            }
            pickerValue={
              unlockedSelection.filter(
                (selection) =>
                  selection.value === selectedContent.unlocked ?? false
              )[0]?.label
            }
            pickerSelection={unlockedSelection}
          />
          <MainSectionForm
            choreographerSelection={choreographerSelection}
            selectedContent={selectedContent}
            handleFileChanges={handleCustomFileChanges}
            handleInputFile={handleCustomFileInputFile}
            handleTextFieldChange={handleTextFieldChange}
            handlePickerChange={handlePickerChange}
            filePreviews={fileInputPreview}
          />
          <MusicSectionForm
            selectedContent={musicContent}
            handleTextFieldChange={handleMusicFormTextFieldChange}
          />
          <ChoreographyCourseSectionForm
            selectedContent={selectedContent}
            handlePickerChange={handlePickerChange}
            handleTextFieldChange={handleTextFieldChange}
          />
          <DanceElementSectionForm
            selectedContent={selectedContent}
            handleTextFieldChange={handleAdditionalInfoChange}
          />
          <VideoBreakdownSectionForm
            videoBreakdownList={videoBreakdown}
            addVideoBreakdown={addVideoBreakdown}
            handleTitleChange={onVideoPartTextFieldChange}
            handleFileChanges={handleFileChanges}
            handlePickerChange={handlePickerChange}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

MainSectionForm.propTypes = {
  choreographerSelection: PropTypes.array,
  selectedContent: PropTypes.object,
  handleInputFile: PropTypes.func,
  handleFileChanges: PropTypes.func,
  handleTextFieldChange: PropTypes.func,
  handlePickerChange: PropTypes.func,
  filePreviews: PropTypes.object,
};

MusicSectionForm.propTypes = {
  selectedContent: PropTypes.object,
  handleTextFieldChange: PropTypes.func,
};

ChoreographyCourseSectionForm.propTypes = {
  selectedContent: PropTypes.object,
  handlePickerChange: PropTypes.func,
  handleTextFieldChange: PropTypes.func,
};

CreateContent.propTypes = {
  selectedContent: PropTypes.object,
};

export default CreateContent;
