import React from "react";
import { VideoBreakdownItem } from "../../../../components";
import { Button } from "antd";
import PropTypes from "prop-types";
import style from "./style.module.css";

/**
 * VideoBreakdownSectionForm is a component to handle any video breakdown input from user
 *
 * @param {Function} handleTitleChange This is a callback that will be called when the user type / do any changes in the title textfield.
 * @param {Function} handleFileChanges This is a callback that will be called when there's any file input changes
 * @param {Array} videoBreakdownList This a video breakdown item array list that will be dynamic
 * @param {Function} addVideoBreakdown This is a callbakc that will be called when the user add new breakdown video
 *
 * @returns VideoBreakdownSectionForm View Component
 */
const VideoBreakdownSectionForm = ({
  handleTitleChange,
  videoBreakdownList,
  addVideoBreakdown,
  handleFileChanges,
}) => {
  return (
    <div>
      <h1 className={style.sectionTitle}>Video Breakdown</h1>
      {videoBreakdownList.map((item, index) => {
        return (
          <VideoBreakdownItem
            key={index}
            index={index}
            choreoDetail={item}
            addVideoBreakdown={addVideoBreakdown}
            handleTitleChange={handleTitleChange}
            handleFileChanges={handleFileChanges}
          />
        );
      })}
      <Button onClick={addVideoBreakdown}>Add Video</Button>
    </div>
  );
};

VideoBreakdownSectionForm.propTypes = {
  videoBreakdownList: PropTypes.array,
  addVideoBreakdown: PropTypes.func,
  handleFileChanges: PropTypes.func,
  handleInputFile: PropTypes.func,
  handleTitleChange: PropTypes.func,
};

export default VideoBreakdownSectionForm;
