import React from "react";
import { Layout } from "antd";
import { NavBar } from "../../components";
import style from "./style.module.css";

// Extract Design Component from AntDesign
const { Header, Content } = Layout;

/**
 * This view component can be use when there's a maintenance for certain page / feature.
 *
 * @returns Maintenance View Component
 */
const Maintenance = () => {
  return (
    <div>
      <Layout>
        <Header className={style.header}>
          <NavBar />
        </Header>
        <Content className={style.mainContent}>
          <div>
            <h1 className={style.mainTitle}>404</h1>
            <h4>Not Found</h4>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default Maintenance;
