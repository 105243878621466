/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { Modal, Button, Input } from "antd";
import FileInput from "../file-input";
import PropTypes from "prop-types";
import style from "./style.module.css";

// Extract Design Component from AntDesign
const { TextArea } = Input;

const FormData = require("form-data");

/**
 * ChoreographerModal is a modal component that use to show detail / edit / add new choreographer
 *
 * @param {Object} choreographer This is a choreographer object get from server
 * @param {Boolean} isModalOpen This is a flag state to indicate was this modal open or not
 * @param {Function} handleSubmit This is a callback that will be called when the user tapped submit
 * @param {Function} handleDelete This is a callback that will be called when the user tapped delete
 * @param {Function} handleCancel This is a callback that will be called when the user tapped cancel
 * @param {Boolean} loadingState This is a flag state to show loading state when user submit new choreographer
 *
 * @returns ChoreographerModal View Component
 */
const ChoreographerModal = ({
  choreographer,
  isModalOpen,
  handleSubmit,
  handleCancel,
  handleDelete,
  loadingState,
}) => {
  /**
   * This is local state variable to save choreographer data current state
   */
  const [choreographerData, setChoreographerData] = useState({
    choreographer_name: "",
    description: "",
    profile_image_url: "",
  });

  /**
   * Handle onChange event when there's update for choreographer name and update the choreographerData state
   *
   * @param {Object} event This is a event object get from onChange callback
   */
  const handleChoreographerNameChange = (event) => {
    let data = choreographerData;
    data["choreographer_name"] = event.target.value;
    setChoreographerData({ ...choreographerData, data });
  };

  /**
   * Handle onChange event when there's update for choreographer decription and update the choreographerData state
   *
   * @param {Object} event This is a event object get from onChange callback
   */
  const handleChoreographerDescriptionChange = (event) => {
    let data = choreographerData;
    data["description"] = event.target.value;
    setChoreographerData({ ...choreographerData, data });
  };

  /**
   * Handle onClick event when user want to input file
   *
   * @param {Object} event This is a event object get from onClick callback
   */
  const handleInputFile = (event) => {
    document.querySelector(`.fileInput${event.target.dataset.id}`).click();

    console.log("handleInputFile", event);
  };

  /**
   * Handle onChange event when there's update for choreographer.image and update the choreographerData state
   *
   * @param {Object} event This is a event object get from onChange callback
   */
  const handleFileChanges = (event) => {
    console.log("handleFileChanges", event);

    let data = { ...choreographerData };
    let baseFormData = new FormData();
    baseFormData.append("file", event.target.files[0]);
    data["profile_image_url_form"] = baseFormData;
    setChoreographerData(data);
  };

  /**
   * This function is use to reset / clear choreographerData state when user submit new data
   */
  const resetChoreographerData = () => {
    let data = choreographerData;
    data["choreographer_name"] = "";
    data["description"] = "";
    data["profile_image_url"] = "";
    setChoreographerData(data);
  };

  /**
   * useEffect will be called once to check if there's choreographer data from server or not
   */
  useEffect(() => {
    if (choreographer != null) {
      let data = choreographerData;
      data["choreographer_name"] = choreographer.choreographer_name;
      data["description"] = choreographer.description;
      data["profile_image_url"] = choreographer.profile_image_url;
      data["choreographer_id"] = parseInt(choreographer.choreographer_id);
      setChoreographerData(data);
    }
  }, []);

  return (
    <Modal
      title={choreographer ? "Choreographer Detail" : "Create Choreographer"}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Button
          key="delete"
          onClick={() => handleDelete(choreographer.choreographer_id)}>
          Delete
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingState}
          onClick={() => {
            handleSubmit(choreographerData);
            resetChoreographerData();
          }}>
          Submit
        </Button>,
      ]}>
      <FileInput
        inputIdentifier="choreographerImage"
        filePreviews={choreographerData.profile_image_url}
        handleInputFile={handleInputFile}
        handleFileChanges={handleFileChanges}
      />
      <Input
        className={style.nameTextfield}
        placeholder="Choreographer Name"
        value={choreographerData.choreographer_name}
        onChange={handleChoreographerNameChange}
      />
      <TextArea
        className={style.descriptionTextfield}
        style={{ resize: "none" }}
        rows={4}
        placeholder="Choreographer Descriptions"
        value={choreographerData.description}
        onChange={handleChoreographerDescriptionChange}
      />
    </Modal>
  );
};

ChoreographerModal.propTypes = {
  choreographer: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  loadingState: PropTypes.bool,
};

export default ChoreographerModal;
