import React from "react";
import { Layout, Menu } from "antd";
import { HomeOutlined, EditOutlined, UserOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import style from "./style.module.css";

// Extract Design Component from AntDesign
const { Sider } = Layout;
const { Item } = Menu;

/**
 * Sidebar is a component that act as a side navigation bar to show all available feature
 *
 * @param navigationKey This is active navigation that will displayed when the page is displayed
 *
 * @returns SideBar View Component
 */
const SideBar = ({ navigationKey }) => {
  return (
    <Sider className={style.navigationBar}>
      <Menu mode="inline" defaultSelectedKeys={navigationKey}>
        <Item
          key="home"
          icon={<HomeOutlined />}
          className={style.navigationItem}
        >
          <Link to="/home">Home</Link>
        </Item>
        <Item
          key="create"
          icon={<EditOutlined />}
          className={style.navigationItem}
        >
          <Link to="/create">Create Content</Link>
        </Item>
        <Item
          key="choreographers"
          icon={<UserOutlined />}
          className={style.navigationItem}
        >
          <Link to="/choreographers">Choreographers</Link>
        </Item>
      </Menu>
    </Sider>
  );
};

SideBar.propTypes = {
  navigationKey: PropTypes.string,
};

export default SideBar;
