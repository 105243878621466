import React from "react";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/kora-logo.png";
import { Button } from "antd";
import PropTypes from "prop-types";

/**
 * ActionButton is a view component that give action / options on top navigation bar
 *
 * @param {String} currentNavigation This an identifier where the navigation bar is being use
 * @param {Function} onClick This is a callback that will be called when user tap the action button
 *
 * @returns ActionButton View Component
 */
const ActionButton = ({ currentNavigation, onClick }) => {
  if (currentNavigation == "create")
    return (
      <Button onClick={onClick} type="primary">
        Upload Content
      </Button>
    );
};

ActionButton.propTypes = {
  currentNavigation: PropTypes.string,
  onClick: PropTypes.func,
};

/**
 * This is simple navigation bar component that will be use in all page that include
 * - Kora logo
 * - Kora name
 * - (Optional) action button
 *
 * @param currentNavigation This an identifier where the navigation bar is being use
 * @param onClick This is a callback that will be called when user tap the action button
 *
 * @returns NavBar View Component
 */
const NavBar = ({ currentNavigation, onClick }) => {
  return (
    <div className={style.navbar}>
      <div className={style.navbarSection}>
        <img src={logo} alt="Kora Dance Logo" />
        <Link to="/">
          <h1>Kora Dance</h1>
        </Link>
      </div>
      <ActionButton onClick={onClick} currentNavigation={currentNavigation} />
    </div>
  );
};

NavBar.propTypes = {
  currentNavigation: PropTypes.string,
  onClick: PropTypes.func,
};

export default NavBar;
