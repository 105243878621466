import axios from "axios";
import { STATIC_AUTH_TOKEN, REST_ENDPOINT } from "../../utils/constants.js";

export const upsertChoreographer = (choreographer) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  return axios.post(
    `${REST_ENDPOINT}/kora/choreographer/upsert`,
    choreographer,
    config
  );
};

export const deleteChoreographer = (choreographerId) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  return axios.delete(
    `${REST_ENDPOINT}/kora/choreographer/delete?choreographer_id=${choreographerId}`,
    config
  );
};

export const uploadFileChoreographer = (
  choreographerId,
  file,
  fileCategory
) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(
    `${REST_ENDPOINT}/kora/choreographer/file?choreographer_id=${choreographerId}&file_category=${fileCategory}`,
    file,
    config
  );
};
