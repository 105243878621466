import axios from "axios";
import { STATIC_AUTH_TOKEN, REST_ENDPOINT } from "../../utils/constants.js";

export const getAllMusic = () => {
  const config = {
    headers: {
      Authorization: `Bearer SFMyNTY=.eyJDbGFpbUF1ZGllbmNlIjoiYXBwbGljYXRpb24ua29yYWRhbmNlLmNvbSIsIkNsYWltSXNzdWVyIjoia29yYWRhbmNlLmNvbSIsIlRva2VuRXhwaXJ5IjoxNjg1OTg3ODMzLCJVc2VyRGF0YSI6eyJ1c2VyX2lkIjoxLCJ1c2VyX2lkZW50aXR5IjoiaGVucmljby5mZXJkaWFuQGtvcmFkYW5jZS5jb20iLCJmaXJzdF9uYW1lIjoiSGVucmljbyIsImxhc3RfbmFtZSI6IkZlcmRpYW4iLCJ1c2VyX3R5cGUiOjF9fQ==.8ompq+ygE/8OSUHCyhW8S1oWSfYypkzmwro5wD0sywo=`,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${REST_ENDPOINT}/kora/music/getList`, config);
};

export const upsertMusic = (music) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${REST_ENDPOINT}/kora/music/upsert`, music, config);
};

export const deleteMusic = (musicId) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  return axios.delete(
    `${REST_ENDPOINT}/kora/music/delete?music_id=${musicId}`,
    config
  );
};
