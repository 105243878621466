import axios from "axios";
import { STATIC_AUTH_TOKEN, REST_ENDPOINT } from "../../utils/constants.js";

export const createChoreo = (choreo) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${REST_ENDPOINT}/insert/choreo/parent`, choreo, config);
};

export const updateChoreo = (choreo) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${REST_ENDPOINT}/update/choreo/parent`, choreo, config);
};

export const deleteChoreo = (choreoId) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  return axios.delete(
    `${REST_ENDPOINT}/delete/choreo/parent?choreo_id=${choreoId}`,
    config
  );
};

export const uploadFileChoreo = (choreoId, file, fileCategory) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(
    `${REST_ENDPOINT}/upload/choreo/parent/file?choreo_id=${choreoId}&file_category=${fileCategory}`,
    file,
    config
  );
};

export const createChoreoDetail = (choreoDetail) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  return axios.post(
    `${REST_ENDPOINT}/insert/choreo/detail`,
    choreoDetail,
    config
  );
};

export const updateChoreoDetail = (choreoDetail) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  return axios.post(
    `${REST_ENDPOINT}/update/choreo/detail`,
    choreoDetail,
    config
  );
};

export const deleteChoreoDetail = (choreoDetailId) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  return axios.delete(
    `${REST_ENDPOINT}/delete/choreo/detail?choreo_detail_id=${choreoDetailId}`,
    config
  );
};

export const uploadFileChoreoDetail = (choreoDetailId, file, fileCategory) => {
  const config = {
    headers: {
      Authorization: `Static ${STATIC_AUTH_TOKEN}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(
    `${REST_ENDPOINT}/upload/choreo/detail/file?choreo_detail_id=${choreoDetailId}&file_category=${fileCategory}`,
    file,
    config
  );
};
