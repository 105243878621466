import React from "react";
import style from "./style.module.css";
import PropTypes from "prop-types";

/**
 * FileInput is a component for input image / video file
 *
 * @param {String} inputIdentifier This is an identifier that will be use to pass identifier when user insert file and the identifier will act as an object property
 * @param {String} filePreviews This is a file path that will be use to display image preview. (Currently only work for image)
 * @param {Function} handleInputFile This is a callback that will be called user tapped the component for input the file
 * @param {Function} handleFileChanges This is a callback that will be called when there's a file changing from filePreviews
 *
 * @returns FileInput View Component
 */
const FileInput = ({
  inputIdentifier,
  filePreviews,
  handleInputFile,
  handleFileChanges,
}) => {
  return (
    <div>
      <div
        data-id={inputIdentifier}
        onClick={handleInputFile}
        className={style.fileInput}
      >
        <input
          type="file"
          title="File Input"
          className={`fileInput${inputIdentifier}`}
          hidden
          onChange={handleFileChanges}
          data-id={inputIdentifier}
        />
        {filePreviews ? (
          <img
            data-id={inputIdentifier}
            src={filePreviews}
            className={style.fileInput}
          />
        ) : (
          <h1 data-id={inputIdentifier} className={style.inputIcon}>
            +
          </h1>
        )}
      </div>
    </div>
  );
};

FileInput.propTypes = {
  inputIdentifier: PropTypes.string,
  filePreviews: PropTypes.any,
  handleInputFile: PropTypes.func,
  handleFileChanges: PropTypes.func,
};

export default FileInput;
