import React, { useState } from "react";
import style from "./style.module.css";
import { Button } from "antd";
import PropTypes from "prop-types";
import ChoreographerModal from "../choreographer-modal";
import {
  upsertChoreographer,
  deleteChoreographer,
  uploadFileChoreographer,
} from "../../api/rest/choreographer-api";
import { apolloClient } from "../../api/gql/client";
import { getAllChoreographerQuery } from "../../api/gql/query";

/**
 * ChoreographerItem is a component to show choreographer data in a form of grid
 *
 * @param {Object} choreographer This is a choreographer object get from server
 *
 * @returns ChoreographerItem View Component
 */
const ChoreographerItem = ({ choreographer }) => {
  /**
   * This is a local state variable to save choreographer modal open state
   */
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * This function is use to open the choreographer modal
   */
  const showModal = () => {
    setIsModalOpen(true);
  };

  /**
   * This function is use to submit new / update choreographer data
   *
   * @param {Object} choreographerData This is a choreographerData get from ChoreographerModal
   */
  // eslint-disable-next-line no-unused-vars
  const handleSubmit = (choreographerData) => {
    console.log("Choreographer Data: ", choreographerData);

    upsertChoreographer(choreographerData)
      .then(async (response) => {
        if (response.status == 200) {
          await apolloClient.refetchQueries({
            include: [getAllChoreographerQuery],
          });

          console.log("Upsert choreographer success: ", response);
          let choreographer = response.data.data;

          uploadFileChoreographer(
            choreographer.choreographer_id,
            choreographerData.profile_image_url_form,
            1
          )
            .then(async (response) => {
              console.log("Upload choreographer file success: ", response);

              await apolloClient.refetchQueries({
                include: [getAllChoreographerQuery],
              });
            })
            .catch((error) =>
              console.log("Upload choreographer Failed: ", error)
            );
        }
      })
      .catch((error) => console.log("Upsert choreographer error: ", error));

    setIsModalOpen(false);
  };

  /**
   * This function is use to close / cancel ChoreographerModal
   */
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (choreographerId) => {
    deleteChoreographer(choreographerId)
      .then(async (response) => {
        if (response.status == 200) {
          await apolloClient.refetchQueries({
            include: [getAllChoreographerQuery],
          });
        }
      })
      .catch((error) => console.log("Delete choreographer error: ", error));
  };

  return (
    <div className={style.cardContainer}>
      <div className={style.pictureContainer}>
        <img
          src={choreographer.profile_image_url}
          className={style.profileBackground}
        />
        <img
          src={choreographer.profile_image_url}
          className={style.profileImage}
        />
      </div>
      <div className={style.choreographerName}>
        {choreographer ? choreographer.choreographer_name : "Not Found"}
      </div>
      <Button type="primary" className={style.selectButton} onClick={showModal}>
        Select
      </Button>
      <ChoreographerModal
        choreographer={choreographer}
        isModalOpen={isModalOpen}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleDelete={handleDelete}
      />
    </div>
  );
};

ChoreographerItem.propTypes = {
  choreographer: PropTypes.object,
};

export default ChoreographerItem;
