import React from "react";
import { Button, Input } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import style from "./style.module.css";
import PropTypes from "prop-types";

/**
 * LoginForm is a component that use to show login form that include
 * - username input
 * - password input
 * - submit button
 *
 * @param onSubmit This is a callback that will be called when user tap the sign in button
 * @param onInputValueChange This is a callback that will be called when there is a value change from the textfield
 *
 * @returns LoginForm View Component
 * @public
 */
const LoginForm = ({ onSubmit, onInputValueChange }) => {
  return (
    <div className={style.loginForm}>
      <h4>Please enter your credential.</h4>
      <form>
        <Input
          name="username"
          placeholder="Username"
          size="large"
          onChange={onInputValueChange}
          className={style.input}
        />
        <Input.Password
          name="password"
          placeholder="Password"
          size="large"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          onChange={onInputValueChange}
          className={style.input}
        />
        <Button
          type="primary"
          size="large"
          onClick={onSubmit}
          className={style.button}
        >
          Sign in
        </Button>
      </form>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  onInputValueChange: PropTypes.func,
};

export default LoginForm;
