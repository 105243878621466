import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { NavBar, LoginForm } from "../../components";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";

// Extract Design Component from AntDesign
const { Header, Content } = Layout;

/**
 * The base component that will handle all view component and state in login page.
 * This component is include two different section:
 * - Header
 * - Content
 *
 * @returns Login View Component
 */
const Login = () => {
  /**
   * Navigation property to be able to navigate to other page programatically
   */
  const navigate = useNavigate();

  /**
   * This is local state variable to save login state when user able to login
   */
  const [loginState, setLoginState] = useState(false);

  /**
   * This is local state variable to save login form text field current state
   */
  const [textfieldValue, setTextfieldValue] = useState({
    username: "",
    password: "",
  });

  /**
   * useEffect will be called when Login Page was created and when there is data update
   */
  useEffect(() => {
    // TODO: May need and update when the API is ready
    // If user have been login, then navigate to home
    if (loginState == true) {
      navigate("/home");
    }
  });

  /**
   * This function is use to handle log in submit.
   *
   * @param {*} event This parameter contains any event happening from the button.
   */
  // eslint-disable-next-line no-unused-vars
  const handleSubmit = (event) => {
    // As it says, prevent form submit default behaviour
    event.preventDefault();

    // TODO: Do API call to do authentication and save token to localStorage if available
    setLoginState(true);
  };

  /**
   * This function is use to handle value change from the text field
   *
   * @param {*} event This parameter contains any event happening from the text field changes
   */
  const handleInputValueChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    // This will update the object based on textfield name
    // But also keep the previous value by using spread operator (...)
    setTextfieldValue({
      ...textfieldValue,
      [name]: value,
    });
  };

  return (
    <div>
      <Layout>
        <Header className={style.header}>
          <NavBar />
        </Header>
        <Content className={style.mainContent}>
          <div className={style.greetingContent}>
            <h1 className={style.lightHeader}>Welcome to</h1>
            <h1>Kora Backoffice</h1>
          </div>
          <div className={style.formContent}>
            <LoginForm
              onSubmit={handleSubmit}
              onInputValueChange={handleInputValueChange}
            />
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default Login;
