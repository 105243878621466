import React from "react";
import { Button } from "antd";
import style from "./style.module.css";
import PropTypes from "prop-types";

/**
 * ContentItem is a component that use to show available content in a list
 *
 * @param {Object} content This is a content object from backend response
 * @param {Function} onEdit This is a callback that will be called when user tapped the edit button
 * @param {Function} onDelete This is a callback that will be called when user tapped the delete button
 *
 * @returns ContentItem View Component
 */
const ContentItem = ({ content, onEdit, onDelete }) => {
  return (
    <div className={style.contentItem}>
      <img
        src={content.video_thumbnail_url_cdn}
        className={style.contentThumbnail}
      />
      <div className={style.contentTitleDescriptionContainer}>
        <h1 className={style.contentTitle}>{content.title}</h1>
        <p className={style.contentDescription}>{content.description}</p>
      </div>
      <div className={style.contentAction}>
        <Button
          type="primary"
          size="large"
          className={style.actionEdit}
          onClick={onEdit}
        >
          Edit
        </Button>
        <Button
          type="primary"
          size="large"
          className={style.actionDelete}
          onClick={onDelete}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

ContentItem.propTypes = {
  content: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ContentItem;
