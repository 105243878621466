import React from "react";
import { Input } from "antd";
import style from "./style.module.css";
import PropTypes from "prop-types";

/**
 * TextField is a custom input component from Ant Design that include title above the input field
 *
 * @param {String} title This is a title that will be shown on top of the input field
 * @param {String} textfieldName This is a identifier for the input field
 * @param {String} textfieldValue This is a input value for the input field
 * @param {Function} onInputValueChange This is a callback that will be called when there is a value changing in textfieldValue
 *
 * @returns TextField View Component
 */
const TextField = ({
  title,
  textfieldName,
  textfieldValue,
  onInputValueChange,
}) => {
  return (
    <div>
      <h1 className={style.textfieldTitle}>{title}</h1>
      <Input
        name={textfieldName}
        size="large"
        value={textfieldValue}
        onChange={onInputValueChange}
        className={style.input}
      />
    </div>
  );
};

TextField.propTypes = {
  title: PropTypes.string,
  textfieldName: PropTypes.string,
  textfieldValue: PropTypes.string,
  onInputValueChange: PropTypes.func,
};

export default TextField;
