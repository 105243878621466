import React from "react";
import { Layout } from "antd";
import { NavBar, SideBar, ChoreographerItem } from "../../components";
import style from "./style.module.css";
import { useQuery } from "@apollo/client";
import { getAllChoreographerQuery } from "../../api/gql/query";

// Extract Design Component from AntDesign
const { Header, Content } = Layout;

/**
 * Render list of available choreographers
 *
 * @returns List of available choreographers in a form of ChoreographerItem component
 */
const AvailableChoreographers = () => {
  const { loading, error, data } = useQuery(getAllChoreographerQuery);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className={style.choreographerContainer}>
      {data.GetAllChoreographers.map((content) => {
        return (
          <ChoreographerItem
            key={content.choreographer_id}
            choreographer={content}
          />
        );
      })}
    </div>
  );
};

/**
 * The base component that will handle all view component and state in choreographer page
 * This component is include several section:
 * - Header
 * - SideBar
 * - Content
 *
 * @returns Choreographers View Component
 */
const Choreographers = () => {
  return (
    <Layout>
      <Header className={style.header}>
        <NavBar />
      </Header>
      <Layout>
        <SideBar navigationKey="choreographers" />
        <Content className={style.mainContent}>
          <h1 className={style.mainTitle}>Available Choreographers</h1>
          <AvailableChoreographers />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Choreographers;
