import React from "react";
import { Select } from "antd";
import style from "./style.module.css";
import PropTypes from "prop-types";

/**
 * Picker is a custom select/dropdown component from Ant Design that include title above the select/dropdown component
 *
 * @param {String} title This is a title that will be shown on top of the dropdown
 * @param {String} placeholder This is a placeholder text that will be shown in the dropdown when there's item selected
 * @param {Array} pickerSelection This is an array of object that contains items that will be shown in the dropdown
 * @param {String} pickerValue This is a selected item from dropdown list
 * @param {Function} onInputValueChange This is a callback that will be called when there's a value change from the pickerValue
 *
 * @returns Picker View Component
 */
const Picker = ({
  title,
  placeholder,
  pickerSelection,
  pickerValue,
  onInputValueChange,
}) => {
  return (
    <div>
      <h1 className={style.pickerTitle}>{title}</h1>
      <Select
        value={
          pickerValue == null || pickerValue == undefined ? null : pickerValue
        }
        placeholder={placeholder}
        onChange={onInputValueChange}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={pickerSelection}
        size="large"
        className={style.picker}
      />
    </div>
  );
};

Picker.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  pickerSelection: PropTypes.array,
  pickerValue: PropTypes.string,
  onInputValueChange: PropTypes.func,
};

export default Picker;
