import React from "react";
import { TextField } from "../../../../components";
import style from "./style.module.css";
import PropTypes from "prop-types";

/**
 * DanceElementSectionForm is a component to handle dance element / additional info input from user
 *
 * @param {Object} selectedContent This is a choreo object that get from the server
 * @param {Function} handleTextFieldChange This is a callback that will be called when the user type / do any changes in the textfield based on it's identifier
 *
 * @returns DanceElementSectionForm View Component
 */
const DanceElementSectionForm = ({
  selectedContent,
  handleTextFieldChange,
}) => {
  if (selectedContent != null || selectedContent != undefined) {
    // Parse string value to JSON and make it as an object
    const additionalInfo = JSON.parse(
      selectedContent.additional_info ? selectedContent.additional_info : "{}",
      (_key, value) => {
        return value;
      }
    );

    // Deconstruct object that have been parsed before
    const { dance_style, dance_element } = additionalInfo;

    return (
      <div>
        <h1 className={style.sectionTitle}>Dance Elements Section</h1>
        <TextField
          title="Choreography Genre (Use comma to separate)"
          textfieldName="genre"
          textfieldValue={dance_style}
          onInputValueChange={(event) =>
            handleTextFieldChange(event, "dance_style")
          }
        />
        <TextField
          title="Dance Elements (Use comma to separate)"
          textfieldName="elements"
          textfieldValue={dance_element}
          onInputValueChange={(event) =>
            handleTextFieldChange(event, "dance_element")
          }
        />
      </div>
    );
  }
};

DanceElementSectionForm.propTypes = {
  selectedContent: PropTypes.object,
  handleTextFieldChange: PropTypes.func,
};

export default DanceElementSectionForm;
