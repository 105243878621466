import React from "react";
import { Layout } from "antd";
import { NavBar, SideBar, ContentItem } from "../../components";
import style from "./style.module.css";
import { useQuery } from "@apollo/client";
import { getAllChoreoQuery } from "../../api/gql/query";
import { apolloClient } from "../../api/gql/client";
import { deleteChoreo } from "../../api/rest/choreo-api";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// Extract Design Component from AntDesign
const { Header, Content } = Layout;

/**
 * Render list of available content when the data from backend API is ready
 *
 * @param onEditSelected This is a callback that will be called when user tap the edit button.
 * @param onDeleteSelected This is a callback that will be called when user tap the delete button.
 *
 * @returns List of available content in a form of ContentItem component
 */
const AvailableContents = ({ onEditSelected, onDeleteSelected }) => {
  const { loading, error, data } = useQuery(getAllChoreoQuery);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      {data.GetLatestChoreoList.map((content) => {
        return (
          <ContentItem
            key={content.choreo_id}
            content={content}
            onEdit={() => onEditSelected(content)}
            onDelete={() => onDeleteSelected(content)}
          />
        );
      })}
    </div>
  );
};

AvailableContents.propTypes = {
  onEditSelected: PropTypes.func,
  onDeleteSelected: PropTypes.func,
};

/**
 * The base component that will handle all view component and state in home page.
 * This component is include three different section:
 * - Header
 * - Sidebar
 * - Content
 *
 * @returns Home View Component
 */
const Home = () => {
  /**
   * Navigation property to be able to navigate to other page programatically
   */
  const navigate = useNavigate();

  /**
   * This function is use to handle when user click delete on selected content
   *
   * @param {*} selectedContent Selected content that want to be deleted
   */
  const handleContentDelete = (selectedContent) => {
    deleteChoreo(selectedContent.choreo_id).then(async (response) => {
      if (response.status == 200) {
        await apolloClient.refetchQueries({
          include: [getAllChoreoQuery],
        });
      }
    });
  };

  /**
   * This function is use to handle when user click edit on selected content
   *
   * @param {*} selectedContent Selected content that want to be editted
   */
  const handleContentEdit = (selectedContent) => {
    navigate("/create", { state: { data: selectedContent } });
  };

  return (
    <Layout>
      <Header className={style.header}>
        <NavBar />
      </Header>
      <Layout>
        <SideBar navigationKey="home" />
        <Content className={style.mainContent}>
          <h1 className={style.mainTitle}>Available Contents</h1>
          <AvailableContents
            onEditSelected={handleContentEdit}
            onDeleteSelected={handleContentDelete}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
