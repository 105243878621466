import "./App.css";
import { apolloClient } from "./api/gql/client";
import Router from "./router/router";
import { ApolloProvider } from "@apollo/client";

/**
 * This is the entrypoint of react application.
 *
 * @returns App View Component
 */
const App = () => {
  return (
    <div className="App">
      <ApolloProvider client={apolloClient}>
        <Router />
      </ApolloProvider>
    </div>
  );
};

export default App;
